define("ember-cli-flash/components/flash-message", ["exports", "ember-cli-flash/templates/components/flash-message"], function (_exports, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    next,
    cancel
  } = Ember.run;
  let FlashMessage = _exports.default = (_dec = Ember.computed.readOnly('flash.showProgress'), _dec2 = Ember.computed.not('exiting'), _dec3 = Ember.computed.and('showProgress', 'notExiting'), _dec4 = Ember.computed.readOnly('flash.exiting'), _dec5 = Ember.computed('messageStyle'), _dec6 = Ember.computed('flash.type', 'messageStylePrefix', '_defaultMessageStylePrefix'), _dec7 = Ember.computed('flash.type'), _dec8 = Ember.computed('flash.{showProgress,timeout}'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class FlashMessage extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", _flashMessage.default);
      _defineProperty(this, "active", false);
      _defineProperty(this, "messageStyle", 'bootstrap');
      _initializerDefineProperty(this, "showProgress", _descriptor, this);
      _initializerDefineProperty(this, "notExiting", _descriptor2, this);
      _initializerDefineProperty(this, "showProgressBar", _descriptor3, this);
      _initializerDefineProperty(this, "exiting", _descriptor4, this);
    }
    get _defaultMessageStylePrefix() {
      const isFoundation = this.messageStyle === 'foundation';
      return isFoundation ? 'alert-box ' : 'alert alert-';
    }
    get alertType() {
      const flashType = this.flash.type || '';
      const prefix = this.messageStylePrefix || this._defaultMessageStylePrefix;
      return `${prefix}${flashType}`;
    }
    get flashType() {
      return Ember.String.classify(this.flash.type || '');
    }
    get progressDuration() {
      if (!this.flash?.showProgress) {
        return false;
      }
      const duration = this.flash?.timeout || 0;
      return Ember.String.htmlSafe(`transition-duration: ${duration}ms`);
    }
    _mouseEnter() {
      if (Ember.isPresent(this.flash)) {
        this.flash.preventExit();
      }
    }
    _mouseLeave() {
      if (Ember.isPresent(this.flash) && !this.flash.exiting) {
        this.flash.allowExit();
      }
    }
    _destroyFlashMessage() {
      if (this.flash) {
        this.flash.destroyMessage();
      }
    }
    onClick() {
      const destroyOnClick = this.flash?.destroyOnClick ?? true;
      if (destroyOnClick) {
        this._destroyFlashMessage();
      }
    }
    onClose() {
      this._destroyFlashMessage();
    }
    onDidInsert(element) {
      const pendingSet = next(this, () => {
        Ember.set(this, 'active', true);
      });
      Ember.set(this, 'pendingSet', pendingSet);
      Ember.set(this, '_mouseEnterHandler', this._mouseEnter);
      Ember.set(this, '_mouseLeaveHandler', this._mouseLeave);
      element.addEventListener('mouseenter', this._mouseEnterHandler);
      element.addEventListener('mouseleave', this._mouseLeaveHandler);
    }
    onWillDestroy(element) {
      element.removeEventListener('mouseenter', this._mouseEnterHandler);
      element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      cancel(this.pendingSet);
      this._destroyFlashMessage();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "showProgress", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notExiting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showProgressBar", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "exiting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_defaultMessageStylePrefix", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_defaultMessageStylePrefix"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alertType", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "alertType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flashType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "flashType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progressDuration", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "progressDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWillDestroy", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype), _class);
});