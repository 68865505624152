define("ember-cli-flash/services/flash-messages", ["exports", "ember-cli-flash/flash/object", "ember-cli-flash/utils/object-without", "ember-cli-flash/utils/flash-message-options", "ember-cli-flash/utils/get-with-default"], function (_exports, _object, _objectWithout, _flashMessageOptions, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FlashMessagesService = _exports.default = (_dec = Ember.computed.equal('queue.length', 0).readOnly(), _dec2 = Ember.computed.mapBy('queue', '_guid').readOnly(), _dec3 = Ember.computed.sort('queue', function (a, b) {
    if (a.priority < b.priority) {
      return 1;
    } else if (a.priority > b.priority) {
      return -1;
    }
    return 0;
  }).readOnly(), _dec4 = Ember.computed, _class = class FlashMessagesService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isEmpty", _descriptor, this);
      _initializerDefineProperty(this, "_guids", _descriptor2, this);
      _initializerDefineProperty(this, "arrangedQueue", _descriptor3, this);
      this._setDefaults();
      this.queue = Ember.A();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.clearMessages();
    }
    add(options = {}) {
      this._enqueue(this._newFlashMessage(options));
      return this;
    }
    clearMessages() {
      const flashes = this.queue;
      if (Ember.isNone(flashes)) {
        return;
      }
      flashes.forEach(flash => flash.destroyMessage());
      flashes.clear();
      return this;
    }
    registerTypes(types = Ember.A()) {
      types.forEach(type => this._registerType(type));
      return this;
    }
    peekFirst() {
      return this.queue.firstObject;
    }
    peekLast() {
      return this.queue.lastObject;
    }
    getFlashObject() {
      const errorText = 'A flash message must be added before it can be returned';
      (true && !(this.queue.length) && Ember.assert(errorText, this.queue.length));
      return this.peekLast();
    }
    _newFlashMessage(options = {}) {
      (true && !(this.defaultPreventDuplicates ? options.message : true) && Ember.assert('The flash message cannot be empty when preventDuplicates is enabled.', this.defaultPreventDuplicates ? options.message : true));
      (true && !(options.preventDuplicates ? options.message : true) && Ember.assert('The flash message cannot be empty when preventDuplicates is enabled.', options.preventDuplicates ? options.message : true));
      const flashService = this;
      const allDefaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      const defaults = (0, _objectWithout.default)(allDefaults, ['types', 'injectionFactories', 'preventDuplicates']);
      const flashMessageOptions = Ember.assign({}, defaults, {
        flashService
      });
      for (let key in options) {
        const value = Ember.get(options, key);
        const option = this._getOptionOrDefault(key, value);
        Ember.set(flashMessageOptions, key, option);
      }
      return _object.default.create(flashMessageOptions);
    }
    _getOptionOrDefault(key, value) {
      const defaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      const defaultOption = Ember.get(defaults, key);
      if (Ember.typeOf(value) === 'undefined') {
        return defaultOption;
      }
      return value;
    }
    get flashMessageDefaults() {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      const overrides = (0, _getWithDefault.default)(config, 'flashMessageDefaults', {});
      return (0, _flashMessageOptions.default)(overrides);
    }
    _setDefaults() {
      const defaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      for (let key in defaults) {
        const classifiedKey = Ember.String.classify(key);
        const defaultKey = `default${classifiedKey}`;
        Ember.set(this, defaultKey, defaults[key]);
      }
      this.registerTypes((0, _getWithDefault.default)(this, 'defaultTypes', Ember.A()));
    }
    _registerType(type) {
      (true && !(type) && Ember.assert('The flash type cannot be undefined', type));
      this[type] = (message, options = {}) => {
        const flashMessageOptions = Ember.assign({}, options);
        Ember.setProperties(flashMessageOptions, {
          message,
          type
        });
        return this.add(flashMessageOptions);
      };
    }
    _hasDuplicate(guid) {
      return this._guids.includes(guid);
    }
    _enqueue(flashInstance) {
      const instancePreventDuplicates = flashInstance.preventDuplicates;
      const preventDuplicates = typeof instancePreventDuplicates === 'boolean' ?
      // always prefer instance option over global option
      instancePreventDuplicates : this.defaultPreventDuplicates;
      if (preventDuplicates) {
        const guid = flashInstance._guid;
        if (this._hasDuplicate(guid)) {
          (true && Ember.warn('Attempting to add a duplicate message to the Flash Messages Service', false, {
            id: 'ember-cli-flash.duplicate-message'
          }));
          return;
        }
      }
      return this.queue.pushObject(flashInstance);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_guids", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "arrangedQueue", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "flashMessageDefaults", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "flashMessageDefaults"), _class.prototype), _class);
});