define("ember-pikaday/templates/pikaday-inputless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1XoCi9sI",
    "block": "[[[10,\"input\"],[14,0,\"ember-pikaday-input\"],[14,4,\"hidden\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"ember-pikaday-container\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-pikaday/templates/pikaday-inputless.hbs",
    "isStrictMode": false
  });
});