define("ember-searchable-select/templates/components/searchable-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7UWqSF+B",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[14,0,\"Searchable-select__icon Searchable-select__icon--check\\n    Searchable-select__option-indicator--selected\"],[12],[1,\"\\n    ✓\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,1],[15,0,[29,[\"Searchable-select__option-label\\n  \",[52,[33,1],\"Searchable-select__option-label--selected\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[28,[37,3],[[33,4],[33,5]],null],[33,6]],null]],[1,\"\\n\"],[13]],[],false,[\"if\",\"isSelected\",\"searchable-select-highlight-match\",\"searchable-select-get\",\"option\",\"optionLabelKey\",\"searchText\"]]",
    "moduleName": "ember-searchable-select/templates/components/searchable-select-option.hbs",
    "isStrictMode": false
  });
});