define("letter-avatar/templates/components/letter-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nobo7mB5",
    "block": "[[[10,\"text\"],[14,\"text-anchor\",\"middle\"],[14,\"x\",\"50%\"],[14,\"y\",\"50%\"],[14,\"dy\",\"0.35em\"],[15,\"fill\",[29,[[36,0]]]],[15,\"font-size\",[29,[[36,1]]]],[15,\"font-family\",[29,[[36,2]]]],[12],[1,[34,3]],[13],[1,\"\\n\"]],[],false,[\"textColor\",\"fontSize\",\"fontFamily\",\"initials\"]]",
    "moduleName": "letter-avatar/templates/components/letter-avatar.hbs",
    "isStrictMode": false
  });
});