define("ember-page-title/helpers/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
    `{{page-title}}` helper used to set the title of the current route context.
  
    @public
    @method page-title
   */
  let PageTitle = _exports.default = (_dec = Ember.inject.service('page-title-list'), _class = class PageTitle extends Ember.Helper {
    get tokenId() {
      return Ember.guidFor(this);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "tokens", _descriptor, this);
      this.tokens.push({
        id: this.tokenId
      });
    }
    compute(params, _hash) {
      let hash = Ember.assign({}, _hash, {
        id: this.tokenId,
        title: params.join('')
      });
      this.tokens.push(hash);
      this.tokens.scheduleTitleUpdate();
      return '';
    }
    willDestroy() {
      super.willDestroy();
      this.tokens.remove(this.tokenId);
      this.tokens.scheduleTitleUpdate();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "tokens", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});